import React from "react"
import { Row } from "antd"
import { useEffect, useRef } from "react"
import { InnerNode, Renderer } from "@zxch3n/tidy"
import { Card } from "./LayeredVsNonLayered"
import { Container } from "./Container"

export function AestheticRule7() {
  const tidyRoot = {
    x: 0,
    y: 0,
    width: 10,
    height: 10,
    children: [
      {
        x: -82.5,
        y: 50,
        width: 10,
        height: 10,
        children: [
          {
            x: -82.5,
            y: 100,
            width: 300,
            height: 10,
            children: [],
            parentId: 2,
            id: 3,
          },
        ],
        parentId: 1,
        id: 2,
      },
      {
        x: -41.25,
        y: 50,
        width: 10,
        height: 10,
        children: [],
        parentId: 1,
        id: 4,
      },
      {
        x: 0,
        y: 50,
        width: 10,
        height: 10,
        children: [],
        parentId: 1,
        id: 5,
      },
      {
        x: 41.25,
        y: 50,
        width: 10,
        height: 10,
        children: [],
        parentId: 1,
        id: 6,
      },
      {
        x: 82.5,
        y: 50,
        width: 10,
        height: 80,
        children: [],
        parentId: 1,
        id: 7,
      },
    ],
    id: 1,
  }
  const errorRoot = {
    x: 0,
    y: 0,
    width: 10,
    height: 10,
    children: [
      {
        x: -80,
        y: 50,
        width: 10,
        height: 10,
        children: [
          {
            x: -80,
            y: 100,
            width: 300,
            height: 10,
            children: [],
            parentId: 2,
            id: 3,
          },
        ],
        parentId: 1,
        id: 2,
      },
      {
        x: -60,
        y: 50,
        width: 10,
        height: 10,
        children: [],
        parentId: 1,
        id: 4,
      },
      {
        x: -40,
        y: 50,
        width: 10,
        height: 10,
        children: [],
        parentId: 1,
        id: 5,
      },
      {
        x: -20,
        y: 50,
        width: 10,
        height: 10,
        children: [],
        parentId: 1,
        id: 6,
      },
      {
        x: 85,
        y: 50,
        width: 10,
        height: 80,
        children: [],
        parentId: 1,
        id: 7,
      },
    ],
    id: 1,
  }
  return (
    <Container>
      <Row style={{ width: "100%", maxWidth: 800 }}>
        <Card style={{ height: 200 }}>
          <Tree root={errorRoot} />
          <p style={{ color: "rgb(128, 128, 128)", textAlign: "center" }}>
            Violet Aesthetic Rule 7
          </p>
        </Card>
        <Card style={{ height: 200 }}>
          <Tree root={tidyRoot} />
          <p style={{ color: "rgb(128, 128, 128)", textAlign: "center" }}>
            Obey Aesthetic Rule 7
          </p>
        </Card>
      </Row>
    </Container>
  )
}

export function Tree({ root }: { root: InnerNode }) {
  const renderRef = useRef<Renderer>()
  const containerRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    const func = async () => {
      renderRef.current = new Renderer(containerRef.current!, { dark: true })
      renderRef.current.init(root)
    }

    func()
    return () => {
      renderRef.current?.dispose()
    }
  }, [])

  return (
    <div
      ref={containerRef}
      style={{ width: "100%", flexGrow: 1, padding: 16 }}
    />
  )
}

export function ClusterAtCenter() {
  const errorRoot = {
    x: 0,
    y: 0,
    width: 10,
    height: 10,
    children: [
      {
        x: -80,
        y: 50,
        width: 10,
        height: 10,
        children: [
          {
            x: -80,
            y: 100,
            width: 300,
            height: 10,
            children: [],
            parentId: 2,
            id: 3,
          },
        ],
        parentId: 1,
        id: 2,
      },
      {
        x: -20,
        y: 50,
        width: 10,
        height: 10,
        children: [],
        parentId: 1,
        id: 4,
      },
      {
        x: 0,
        y: 50,
        width: 10,
        height: 10,
        children: [],
        parentId: 1,
        id: 5,
      },
      {
        x: 20,
        y: 50,
        width: 10,
        height: 10,
        children: [],
        parentId: 1,
        id: 6,
      },
      {
        x: 85,
        y: 50,
        width: 10,
        height: 80,
        children: [],
        parentId: 1,
        id: 7,
      },
    ],
    id: 1,
  }

  return (
    <Container>
      <Row
        style={{
          width: "100%",
          maxWidth: 800,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Card style={{ height: 200 }}>
          <Tree root={errorRoot} />
          <p style={{ color: "rgb(128, 128, 128)", textAlign: "center" }}>
            Taking average positions
          </p>
        </Card>
      </Row>
    </Container>
  )
}
