import React, { useEffect, useLayoutEffect, useRef } from "react"
import * as echarts from "echarts/core"
import {
  TooltipComponent,
  GridComponent,
  LegendComponent,
  TitleComponent,
} from "echarts/components"
import { LineChart } from "echarts/charts"
import { UniversalTransition } from "echarts/features"
import { CanvasRenderer } from "echarts/renderers"
import { EChartsOption } from "echarts/types/dist/shared"
import { ECharts } from "echarts/core"

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  LineChart,
  CanvasRenderer,
  UniversalTransition,
])

export function Chart({
  tidy,
  naive,
  style,
  loading,
}: {
  tidy: { num: number; time: number }[]
  naive: { num: number; time: number }[]
  style?: React.CSSProperties
  loading?: boolean
}) {
  const ref = useRef<HTMLDivElement>(null)
  const chartRef = useRef<ECharts>()
  useLayoutEffect(() => {
    if (!ref.current) {
      return
    }

    const myChart = echarts.init(ref.current, "dark")
    chartRef.current = myChart
    const option: EChartsOption = {
      title: {
        text: "Benchmark",
        padding: 20,
      },
      tooltip: {
        trigger: "item",
        formatter: _params => {
          const params = _params as { value: number; name: number }
          return `${params.name / 1000}K Nodes<br/>${params.value.toFixed(
            2
          )} ms`
        },
      },
      legend: {
        show: true,
      },
      grid: {
        top: 80,
        left: 80,
      },
      xAxis: {
        type: "category",
        data: tidy.map(x => x.num),
        name: "Number of Nodes",
        nameLocation: "middle",
        nameGap: 30,
        nameTextStyle: { fontWeight: 700 },
      },
      yAxis: {
        type: "value",
        name: "Time(ms)",
        nameLocation: "middle",
        nameGap: 40,
        nameTextStyle: { fontWeight: 700 },
      },
      series: [
        {
          name: "Tidy WASM",
          data: tidy.map(x => x.time),
          type: "line",
        },
        {
          name: "Naive WASM",
          data: naive.map(x => x.time),
          type: "line",
        },
      ],
    }

    myChart.setOption(option)
  }, [])

  useEffect(() => {
    const myChart = chartRef.current!
    const option: EChartsOption = {
      xAxis: {
        type: "category",
        data: tidy.map(x => x.num),
        name: "Number of Nodes",
        nameLocation: "middle",
        nameGap: 30,
        nameTextStyle: { fontWeight: 700 },
      },
      series: [
        {
          name: "Tidy WASM",
          data: tidy.map(x => x.time),
          type: "line",
        },
        {
          name: "Naive WASM",
          data: naive.map(x => x.time),
          type: "line",
        },
      ],
    }

    myChart.setOption(option)
  }, [tidy, naive])

  useEffect(() => {
    if (loading == null) {
      return
    }

    if (loading) {
      chartRef.current?.showLoading()
    } else {
      chartRef.current?.hideLoading()
    }
  }, [loading])

  return (
    <div
      className="chart"
      ref={ref}
      style={{ width: "100%", height: 400, maxWidth: 800, ...style }}
    ></div>
  )
}
