import React from "react"
import { LayoutTypeStr, TidyComponent } from "@zxch3n/tidy"
import { Col, Row } from "antd"
import { Container } from "./Container"

const root = {
  id: 572082269,
  height: 12,
  width: 52,
  x: 25.0625,
  y: 103,
  children: [
    {
      id: 2077927524,
      height: 14,
      width: 19,
      x: -75.3125,
      y: 207,
      children: [
        {
          id: 431728128,
          height: 10,
          width: 18,
          x: -129.3125,
          y: 261,
          children: [
            {
              id: 1014380243,
              height: 16,
              width: 12,
              x: -129.3125,
              y: 311,
              children: [
                {
                  id: 2084369754,
                  height: 70,
                  width: 17,
                  x: -180.8125,
                  y: 367,
                  children: [
                    {
                      id: 26612867,
                      height: 19,
                      width: 15,
                      x: -194.0625,
                      y: 459,
                      children: [
                        {
                          id: 823509491,
                          height: 10,
                          width: 15,
                          x: -194.0625,
                          y: 518,
                          children: [],
                          parentId: 26612867,
                        },
                      ],
                      parentId: 2084369754,
                    },
                    {
                      id: 1971768185,
                      height: 10,
                      width: 18,
                      x: -167.5625,
                      y: 459,
                      children: [],
                      parentId: 2084369754,
                    },
                  ],
                  parentId: 1014380243,
                },
                {
                  id: 757842076,
                  height: 19,
                  width: 16,
                  x: -129.3125,
                  y: 367,
                  children: [],
                  parentId: 1014380243,
                },
                {
                  id: 1681360662,
                  height: 13,
                  width: 12,
                  x: -77.8125,
                  y: 367,
                  children: [
                    {
                      id: 1104191376,
                      height: 14,
                      width: 17,
                      x: -77.8125,
                      y: 420,
                      children: [],
                      parentId: 1681360662,
                    },
                  ],
                  parentId: 1014380243,
                },
              ],
              parentId: 431728128,
            },
          ],
          parentId: 2077927524,
        },
        {
          id: 1183198989,
          height: 73,
          width: 81,
          x: -21.3125,
          y: 261,
          children: [
            {
              id: 430026685,
              height: 17,
              width: 10,
              x: -21.3125,
              y: 374,
              children: [
                {
                  id: 751090648,
                  height: 14,
                  width: 17,
                  x: -33.8125,
                  y: 431,
                  children: [],
                  parentId: 430026685,
                },
                {
                  id: 849879515,
                  height: 66,
                  width: 13,
                  x: -8.8125,
                  y: 431,
                  children: [],
                  parentId: 430026685,
                },
              ],
              parentId: 1183198989,
            },
          ],
          parentId: 2077927524,
        },
      ],
      parentId: 572082269,
    },
    {
      id: 615326733,
      height: 10,
      width: 16,
      x: 49.1875,
      y: 155,
      children: [
        {
          id: 1798041208,
          height: 11,
          width: 17,
          x: 36.6875,
          y: 205,
          children: [],
          parentId: 615326733,
        },
        {
          id: 197797869,
          height: 65,
          width: 13,
          x: 61.6875,
          y: 205,
          children: [
            {
              id: 792335400,
              height: 11,
              width: 10,
              x: 51.6875,
              y: 310,
              children: [],
              parentId: 197797869,
            },
            {
              id: 1842859835,
              height: 12,
              width: 10,
              x: 71.6875,
              y: 310,
              children: [
                {
                  id: 653907935,
                  height: 76,
                  width: 14,
                  x: 44.6875,
                  y: 362,
                  children: [],
                  parentId: 1842859835,
                },
                {
                  id: 2049880275,
                  height: 15,
                  width: 74,
                  x: 98.6875,
                  y: 362,
                  children: [
                    {
                      id: 1870629802,
                      height: 19,
                      width: 14,
                      x: 98.6875,
                      y: 417,
                      children: [],
                      parentId: 2049880275,
                    },
                  ],
                  parentId: 1842859835,
                },
              ],
              parentId: 197797869,
            },
          ],
          parentId: 615326733,
        },
      ],
      parentId: 572082269,
    },
    {
      id: 327689419,
      height: 100,
      width: 16,
      x: 173.9375,
      y: 264,
      children: [
        {
          id: 148586946,
          height: 18,
          width: 18,
          x: 154.6875,
          y: 362,
          children: [
            {
              id: 1163080646,
              height: 15,
              width: 18,
              x: 154.6875,
              y: 420,
              children: [
                {
                  id: 1729977299,
                  height: 14,
                  width: 14,
                  x: 141.6875,
                  y: 475,
                  children: [],
                  parentId: 1163080646,
                },
                {
                  id: 1537327742,
                  height: 11,
                  width: 14,
                  x: 167.6875,
                  y: 475,
                  children: [
                    {
                      id: 1516780031,
                      height: 12,
                      width: 18,
                      x: 167.6875,
                      y: 526,
                      children: [],
                      parentId: 1537327742,
                    },
                  ],
                  parentId: 1163080646,
                },
              ],
              parentId: 148586946,
            },
          ],
          parentId: 327689419,
        },
        {
          id: 209218935,
          height: 18,
          width: 10,
          x: 193.1875,
          y: 362,
          children: [
            {
              id: 1825104487,
              height: 16,
              width: 17,
              x: 193.1875,
              y: 420,
              children: [],
              parentId: 209218935,
            },
          ],
          parentId: 327689419,
        },
      ],
      parentId: 572082269,
    },
  ],
  parentId: 275765647,
}

export function Card({ children, style, ...props }: any) {
  return (
    <Col
      xl={12}
      lg={12}
      sm={24}
      xs={24}
      style={{
        padding: 4,
      }}
      {...props}
    >
      <div
        style={{
          height: 400,
          border: "1px solid rgba(128,128,128,0.2)",
          borderRadius: 8,
          ...style,
        }}
      >
        <div
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {children}
        </div>
      </div>
    </Col>
  )
}

export function LayeredVsNonLayered() {
  return (
    <Container>
      <Row style={{ maxWidth: 1200, width: "100%" }}>
        <Card>
          <TidyComponent
            root={root}
            layoutType={LayoutTypeStr.Tidy}
            style={{ flexGrow: 1 }}
            theme={{ dark: true }}
          />
          <p
            style={{
              color: "rgb(128, 128, 128)",
              textAlign: "center",
              paddingBottom: 8,
            }}
          >
            Non-Layered
          </p>
        </Card>
        <Card>
          <TidyComponent
            root={root}
            layoutType={LayoutTypeStr.LayeredTidy}
            theme={{ dark: true }}
          />
          <p
            style={{
              color: "rgb(128, 128, 128)",
              textAlign: "center",

              paddingBottom: 8,
            }}
          >
            Layered
          </p>
        </Card>
      </Row>
    </Container>
  )
}
