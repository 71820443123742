import React from "react"
import { Row } from "antd"
import { LayoutTypeStr, node, TidyComponent } from "@zxch3n/tidy"
import { Card } from "./LayeredVsNonLayered"
import { Container } from "./Container"
import { Tree } from "./AestheticRule7"

const root = node(10, 10, [
  node(10, 100),
  node(10, 10),
  node(10, 10, [node(50, 10)]),
  node(10, 10, [node(10, 10, [node(300, 10)])]),
])
export function NotIdealExample() {
  return (
    <Container>
      <Row style={{ maxWidth: 1200, width: "100%" }}>
        <Card style={{ height: 200, padding: 12 }}>
          <TidyComponent
            root={root}
            layoutType={LayoutTypeStr.Tidy}
            style={{ flexGrow: 1 }}
            theme={{ dark: true }}
          />
          <p
            style={{
              color: "rgb(128, 128, 128)",
              textAlign: "center",
              paddingBottom: 8,
            }}
          >
            Current layout
          </p>
        </Card>
        <Card style={{ height: 200 }}>
          <Tree
            root={{
              x: 0,
              y: 0,
              width: 10,
              height: 10,
              children: [
                {
                  x: -82.5,
                  y: 50,
                  width: 10,
                  height: 100,
                  children: [],
                  parentId: 1,
                  id: 2,
                },
                {
                  x: -27.5,
                  y: 50,
                  width: 10,
                  height: 10,
                  children: [],
                  parentId: 1,
                  id: 3,
                },
                {
                  x: 27.5,
                  y: 50,
                  width: 10,
                  height: 10,
                  children: [
                    {
                      x: 27.5,
                      y: 100,
                      width: 50,
                      height: 10,
                      children: [],
                      parentId: 4,
                      id: 5,
                    },
                  ],
                  parentId: 1,
                  id: 4,
                },
                {
                  x: 82.5,
                  y: 50,
                  width: 10,
                  height: 10,
                  children: [
                    {
                      x: 82.5,
                      y: 100,
                      width: 10,
                      height: 10,
                      children: [
                        {
                          x: 82.5,
                          y: 150,
                          width: 300,
                          height: 10,
                          children: [],
                          parentId: 7,
                          id: 8,
                        },
                      ],
                      parentId: 6,
                      id: 7,
                    },
                  ],
                  parentId: 1,
                  id: 6,
                },
              ],
              id: 1,
            }}
          />
          <p
            style={{
              color: "rgb(128, 128, 128)",
              textAlign: "center",
              paddingBottom: 8,
            }}
          >
            Ideal layout
          </p>
        </Card>
      </Row>
    </Container>
  )
}
