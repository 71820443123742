import React from "react"
import "./container.less"

export function Container({
  children,
  style,
}: {
  children?: any
  style?: React.CSSProperties
}) {
  return (
    <div className="zxch3n-blog-container" style={style}>
      {children}
    </div>
  )
}
