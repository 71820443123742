import React, { useLayoutEffect, useRef, useState } from "react"

import { Container } from "./Container"
import { Chart } from "./Chart"
import { bench } from "./benchmark"
import { Button } from "antd"
import { LayoutType } from "@zxch3n/tidy"

export function BenchOnYourDevice() {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<
    | {
        naive: { num: number; time: number }[]
        tidy: { num: number; time: number }[]
      }
    | undefined
  >()
  return (
    <Container
      style={{
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        flexDirection: "column",
      }}
    >
      {data && <Chart tidy={data.tidy} naive={data.naive} loading={loading} />}
      <Button
        style={{ marginTop: 16 }}
        loading={loading}
        onClick={async () => {
          if (loading) {
            return
          }

          setLoading(true)
          const naive = await bench(LayoutType.Basic)
          const tidy = await bench(LayoutType.Tidy)
          setData({
            naive,
            tidy,
          })
          setLoading(false)
        }}
      >
        Run Benchmark On Your Device
      </Button>
    </Container>
  )
}
