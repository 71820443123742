import { AnimateTransform } from "../../../../content/blog/tidy/CoverAnimation.tsx";
import { AestheticRule7, ClusterAtCenter } from "../../../../content/blog/tidy/AestheticRule7.tsx";
import { LayeredVsNonLayered } from "../../../../content/blog/tidy/LayeredVsNonLayered";
import { NotIdealExample } from "../../../../content/blog/tidy/NotIdealExample";
import { TidyExample } from "../../../../content/blog/tidy/TidyInteractive.tsx";
import { BenchOnYourDevice } from "../../../../content/blog/tidy/BenchOnYourDevice.tsx";
import * as React from 'react';
export default {
  AnimateTransform,
  AestheticRule7,
  ClusterAtCenter,
  LayeredVsNonLayered,
  NotIdealExample,
  TidyExample,
  BenchOnYourDevice,
  React
};