import { Col, InputNumber, Row, Select, Slider } from "antd"
import React, { useState } from "react"
import { useDebounce } from "react-use"

import {
  LayoutTypeStr,
  TidyComponent,
  createTree,
  deleteRandomNode,
  insertRandomNodeDepthFirst,
  nodeNum,
} from "@zxch3n/tidy"
import { Container } from "./Container"

const { Option } = Select
const root = createTree(50)

function Label({
  style,
  ...props
}: {
  style?: React.CSSProperties
  children?: any
}) {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        width: 120,
        padding: 4,
        ...style,
      }}
      {...props}
    />
  )
}

export function TidyExample() {
  const [layoutType, setLayoutType] = useState(LayoutTypeStr.Tidy)
  const [updateTrigger, setUpdate] = useState(0)
  const [num, setNum] = useState(50)
  useDebounce(
    () => {
      let currentNum = nodeNum(root)
      if (num < currentNum) {
        deleteRandomNode(root, currentNum - num)
      } else if (num > currentNum) {
        insertRandomNodeDepthFirst(root, num - currentNum)
      }

      setUpdate(updateTrigger => updateTrigger + 1)
    },
    100,
    [num]
  )
  return (
    <Container
      style={{
        margin: "2em auto",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }}
    >
      <div
        style={{
          height: 500,
          padding: 16,
          boxShadow: "0px 0px 8px rgba(128,128,128,0.3)",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          maxWidth: 600,
        }}
      >
        <TidyComponent
          root={root}
          updateTrigger={updateTrigger}
          layoutType={layoutType}
          theme={{ dark: true }}
        />
        <Row
          style={{
            display: "flex",
            marginBottom: 24,
            width: "100%",
          }}
        >
          <Col
            sm={24}
            xs={24}
            md={12}
            lg={12}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Label style={{ width: 140, textAlign: "right" }}>
              Layout Type:
            </Label>
          </Col>
          <Col sm={24} xs={24} md={12} lg={12}>
            <Select
              value={layoutType}
              onChange={v => setLayoutType(v)}
              style={{ width: 150 }}
            >
              <Option value={LayoutTypeStr.Tidy}>Tidy</Option>
              <Option value={LayoutTypeStr.LayeredTidy}>Layered Tidy</Option>
              <Option value={LayoutTypeStr.Basic}>Naive</Option>
            </Select>
          </Col>
        </Row>

        <Row
          style={{
            display: "flex",
            width: "100%",
            maxWidth: 600,
          }}
        >
          <Col
            sm={24}
            xs={24}
            md={12}
            lg={12}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <InputNumber
              value={num}
              min={1}
              max={500}
              onChange={v => setNum(v)}
              addonAfter="Nodes"
              style={{ width: 140, marginRight: 20 }}
            />
          </Col>
          <Col sm={24} xs={24} md={12} lg={12}>
            <Slider
              value={num}
              onChange={v => setNum(v)}
              min={1}
              max={500}
              style={{ width: 200 }}
            />
          </Col>
        </Row>
      </div>
    </Container>
  )
}
