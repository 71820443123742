/**
 * Run with
 * node --expose-gc test/benchmark.mjs
 */
import { TidyLayout, LayoutType, createNode, InnerNode } from "@zxch3n/tidy"

const STEP = 5000
const MAX = 100_000
export async function bench(type: LayoutType) {
  const [root, arr] = insertNewNodes(0)
  const perf: { num: number; time: number }[] = []
  const init = performance.now()
  for (let num = STEP; num < MAX; num += STEP) {
    const tidy = await TidyLayout.create(type)
    insertNewNodes(STEP, root, arr)
    tidy.set_root(root)
    await new Promise(r => setTimeout(r, 0))
    const start = performance.now()
    tidy.layout()
    const duration = performance.now() - start
    perf.push({ time: duration, num })
    tidy.dispose()
    await new Promise(r => setTimeout(r, 0))
    if (performance.now() - init > 2000) {
      break
    }
  }

  return perf
}

function insertNewNodes(
  num: number,
  root: InnerNode = createNode() as InnerNode,
  arr: InnerNode[] = [root]
): [InnerNode, InnerNode[]] {
  for (let i = 0; i < num; i++) {
    let parentIndex = 0
    parentIndex = (arr.length * Math.random()) | 0
    const parent = arr[parentIndex]
    const child = createNode() as InnerNode
    parent.children.push(child)
    child.parentId = parent.id
    arr.push(child)
  }

  return [root, arr]
}
